<template>
	<div style="height: 100vh;" v-loading="loading">
		<div class="container" v-if="pageShow" :style="{'backgroundImage':`url(${bj_img})`}">
			<div class="boxstys top">
				<div class="left">
					<img class="logo" :src="require('assets/login/logo.png')">
					<span class="text">民盛人力</span>
				</div>
			</div>
			<div class="box">
				<div class="infobox">
					<div class="title">民盛人力数字管理系统</div>
					<div class="introduce">民盛人力</div>
				</div>
				<userlogin guardName="orange_admin_user"></userlogin>
			</div>
			<div class="boxstys bottom">Copyright2018-{{year}} 四川赤道猩数字软件科技有限公司 蜀ICP备2023004506号-1</div>
		</div>
	</div>
	
</template>

<script>
	import userlogin from '@/components/login/login.vue';
	import { eltips } from '@/util/util';
	export default {
		components: { userlogin },
		name: 'adminlogin',
		data() {
			return {
				setInfo:null,
				loading:false,
				notFind:false,
				notSet:false,
				pageShow:true,
				year:new Date().getFullYear(),
				bj_img:require('@/assets/images/logobg1.png')
			};
		},
		created() {
			sessionStorage.setItem('shopId',25079057)
		},
		methods: {
			
		}
	};
</script>

<style scoped lang="less">
	.wscn-http404-container {
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		
		.wscn-http404 {
			position: relative;
			// width: 1200px;
			margin: 0 auto;
			min-height: 100%;
			overflow: hidden;
		
			.pic-404 {
				position: relative;
				float: left;
				width: 600px;
				overflow: hidden;
		
				&__parent {
					width: 100%;
				}
		
				&__child {
					position: absolute;
		
					&.left {
						width: 80px;
						top: 17px;
						left: 220px;
						opacity: 0;
						animation-name: cloudLeft;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1s;
						animation-iteration-count:infinite;
					}
		
					&.mid {
						width: 46px;
						top: 10px;
						left: 420px;
						opacity: 0;
						animation-name: cloudMid;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1.2s;
						animation-iteration-count:infinite;
					}
		
					&.right {
						width: 62px;
						top: 100px;
						left: 500px;
						opacity: 0;
						animation-name: cloudRight;
						animation-duration: 2s;
						animation-timing-function: linear;
						animation-fill-mode: forwards;
						animation-delay: 1s;
						animation-iteration-count:infinite;
					}
		
					@keyframes cloudLeft {
						0% {
							top: 17px;
							left: 220px;
							opacity: 0;
						}
		
						20% {
							top: 33px;
							left: 188px;
							opacity: 1;
						}
		
						80% {
							top: 81px;
							left: 92px;
							opacity: 1;
						}
		
						100% {
							top: 97px;
							left: 60px;
							opacity: 0;
						}
					}
		
					@keyframes cloudMid {
						0% {
							top: 10px;
							left: 420px;
							opacity: 0;
						}
		
						20% {
							top: 40px;
							left: 360px;
							opacity: 1;
						}
		
						70% {
							top: 130px;
							left: 180px;
							opacity: 1;
						}
		
						100% {
							top: 160px;
							left: 120px;
							opacity: 0;
						}
					}
		
					@keyframes cloudRight {
						0% {
							top: 100px;
							left: 500px;
							opacity: 0;
						}
		
						20% {
							top: 120px;
							left: 460px;
							opacity: 1;
						}
		
						80% {
							top: 180px;
							left: 340px;
							opacity: 1;
						}
		
						100% {
							top: 200px;
							left: 300px;
							opacity: 0;
						}
					}
				}
			}
		
			.bullshit {
				position: relative;
				float: left;
				width: 300px;
				padding: 30px 0;
				overflow: hidden;
		
				&__oops {
					font-size: 32px;
					font-weight: bold;
					line-height: 40px;
					color: #1482f0;
					opacity: 0;
					margin-bottom: 20px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-fill-mode: forwards;
				}
		
				&__headline {
					font-size: 20px;
					line-height: 24px;
					color: #222;
					font-weight: bold;
					opacity: 0;
					margin-bottom: 10px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.1s;
					animation-fill-mode: forwards;
				}
		
				&__info {
					font-size: 13px;
					line-height: 21px;
					color: grey;
					opacity: 0;
					margin-bottom: 30px;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.2s;
					animation-fill-mode: forwards;
				}
		
				&__return-home {
					display: block;
					float: left;
					width: 110px;
					height: 36px;
					background: #1482f0;
					border-radius: 100px;
					text-align: center;
					color: #ffffff;
					opacity: 0;
					font-size: 14px;
					line-height: 36px;
					cursor: pointer;
					animation-name: slideUp;
					animation-duration: 0.5s;
					animation-delay: 0.3s;
					animation-fill-mode: forwards;
				}
		
				@keyframes slideUp {
					0% {
						transform: translateY(60px);
						opacity: 0;
					}
		
					100% {
						transform: translateY(0);
						opacity: 1;
					}
				}
			}
		}
	}
	
	.container{
		position: relative;
		background-size: 100% 100%;
		height: 100vh !important;
		overflow: hidden;
		display:flex;
		flex-direction: column;
		justify-content: space-between;
		.box {
			width: 80%;
			margin: 0 auto;
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			.infobox{
				color: #F3F3F3;
				position: relative;
				height:auto;
				.title{
					font-size: 32px;
					padding-bottom: 38px;
					position: relative;
					&::after{
						content: '';
						position: absolute;
						left: 0;
						bottom: 0;
						width: 200px;
						height: 2px;
						background: #F3F3F3;
					}
				}
				.introduce{
					font-size: 16px;
					min-width: 500px;
					margin-top: 38px;
					text-align: justify;
					position: absolute;
					left: 0;
					top: 100%;
				}
			}
		}
		
		.boxstys {
			width: 100%;
			height: 100px;
			background: rgba(0, 0, 0, 0.5);
		}
		
		.bottom {
			color: #ffffff;
			text-align: center;
			line-height: 80px;
			height: 80px;
			font-size: 14px;
		}
		
		.top {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 100px;
			height: 66px;
			.left {
				display: flex;
				align-items: center;
				.logo {
					width: 40px;
					height: 40px;
					border-radius: 6px;
				}
				.text {
					font-size: 28px;
					font-weight: bold;
					margin-left: 12px;
					color: #1989fa;
				}
			}
		
			.right {
				width: 66px;
				height: 66px;
				background: rgba(255, 255, 255, 0.2);
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: 16px;
				color: #ffffff;
			}
		}
	}
	
</style>
